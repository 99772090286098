import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MainLayoutComponent } from '@mei/common/shared/layouts/main-layout/main-layout.component';

/** Root component. */
@Component({
	selector: 'meiw-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [RouterOutlet, MainLayoutComponent],
})
export class AppComponent {}
